import { useApi } from '~/api';
import { components, operations } from '~~/api-types';
import { UserDto } from '~/api/auth';
import { sleep } from '~/utils/common';

export enum SocialPostStatus {
  INITALIZED = 'initialized',
  PROCESSING = 'processing',
  POSTING = 'posting',
  SCHEDULED = 'scheduled',
  POSTED = 'posted',
  FAILED = 'failed',
}

export type PaginatedSocialPostsDto =
  components['schemas']['PaginatedSocialPostsResponseDto'];
export type GetSocialPostsListQueryParams =
  operations['AppGetSocialPostsListEndpoint_handler']['parameters']['query'];

export const apiGetSocialPostsList = (
  params?: GetSocialPostsListQueryParams,
): Promise<PaginatedSocialPostsDto> => {
  const api = useApi();

  return api(`/app/social-posts`, {
    params,
  });
};

export type CreatePrivatePostBodyDto =
  components['schemas']['CreatePrivatePostBodyDto'];
export type AppCreatePrivatePostDto =
  components['schemas']['AppCreatePrivatePostResponseDto'];

export const apiCreateSocialPost = (
  body: CreatePrivatePostBodyDto,
): Promise<AppCreatePrivatePostDto> => {
  const api = useApi();

  return api(`/app/social-posts`, {
    method: 'POST',
    body,
  });
};

export const apiMarkUserUsedPrivatePosts = (): Promise<UserDto> => {
  const api = useApi();

  return api(`/app/social-posts/mark-as-used-private-posts`, {
    method: 'POST',
  });
};

export const apiDeleteSocialPost = (socialPostId: number) => {
  const api = useApi();

  return api(`/app/social-posts/${socialPostId}`, {
    method: 'DELETE',
  });
};

export type UpdateSocialPostBodyDto =
  components['schemas']['UpdateSocialPostBodyDto'];

export const apiEditSocialPost = async (
  socialPostId: number,
  body: UpdateSocialPostBodyDto,
): Promise<void> => {
  const api = useApi();

  await api(`/app/social-posts/${socialPostId}`, {
    method: 'PATCH',
    body,
  });
};

export type PostSocialPostAgainBodyDto =
  components['schemas']['PostSocialPostAgainBodyDto'];
export type AppPostSocialPostAgainDto =
  components['schemas']['AppPostSocialPostAgainResponseDto'];

export const apiPostAgainSocialPost = (
  socialPostId: number,
  body: PostSocialPostAgainBodyDto,
): Promise<AppPostSocialPostAgainDto> => {
  const api = useApi();

  return api(`/app/social-posts/${socialPostId}/post-again`, {
    method: 'POST',
    body,
  });
};

export type MetaPageResponseDto = components['schemas']['MetaPageResponseDto'];

export type PrepostFacebookBodyDto =
  components['schemas']['PrepostFacebookBodyDto'];

export const apiPrepostFacebook = (
  body: PrepostFacebookBodyDto,
): Promise<MetaPageResponseDto> => {
  const api = useApi();

  return api(`/app/social-posts/prepost-facebook`, {
    method: 'POST',
    body,
  });
};

export type PrepostInstagramBodyDto =
  components['schemas']['PrepostInstagramBodyDto'];

export const apiPrepostInstagram = (
  body: PrepostInstagramBodyDto,
): Promise<MetaPageResponseDto> => {
  const api = useApi();

  return api(`/app/social-posts/prepost-instagram`, {
    method: 'POST',
    body,
  });
};

export type SocialPostDto = components['schemas']['SocialPostResponseDto'];

export const apiGetSingleSocialPost = (
  state: string,
): Promise<SocialPostDto> => {
  const api = useApi();

  return api(`/app/social-posts/${state}`);
};

export const pollSocialPost = async (
  state: string,
): Promise<null | SocialPostDto> => {
  const res = await apiGetSingleSocialPost(state);

  if (
    res.status === SocialPostStatus.PROCESSING ||
    res.status === SocialPostStatus.POSTING
  ) {
    await sleep(100);
    return pollSocialPost(state);
  }

  return res;
};

export type PostLinkedinBodyDto = components['schemas']['PostLinkedinBodyDto'];

export const apiPostLinkedin = (
  body: PostLinkedinBodyDto,
): Promise<SocialPostDto> => {
  const api = useApi();

  return api(`/app/social-posts/post-linkedin`, {
    method: 'POST',
    body,
  });
};

export type PostFacebookBodyDto = components['schemas']['PostFacebookBodyDto'];

export const apiPostFacebook = (
  body: PostFacebookBodyDto,
): Promise<SocialPostDto> => {
  const api = useApi();

  return api(`/app/social-posts/post-facebook`, {
    method: 'POST',
    body,
  });
};

export type PostInstagramBodyDto =
  components['schemas']['PostInstagramBodyDto'];

export const apiPostInstagram = (
  body: PostInstagramBodyDto,
): Promise<SocialPostDto> => {
  const api = useApi();

  return api(`/app/social-posts/post-instagram`, {
    method: 'POST',
    body,
  });
};

export type PostTwitterBodyDto = components['schemas']['PostTwitterBodyDto'];

export const apiPostTwitter = (
  body: PostTwitterBodyDto,
): Promise<SocialPostDto> => {
  const api = useApi();

  return api(`/app/social-posts/post-twitter`, {
    method: 'POST',
    body,
  });
};
